import axios from "axios";
import getUrlParam from "../utils/url";
import { API_BASE_URL } from "../constants/api-url";

const token = getUrlParam('token');

const restClient = axios.create({
    baseURL: API_BASE_URL,
    params: {
        token,
    }
});

export default restClient;