import { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import useJwt from "./use-jwt";
import getUrlParam from "../utils/url";
import AppContext from "../app-context";
import { ERROR, SUMMARY } from "../constants/routes";
import { WS_BASE_URL } from "../constants/api-url";
import {Report} from "../models/report";

const useWebSocket = () => {
    const ws = useRef<WebSocket>();
    const { getJwt } = useJwt();
    const navigate = useNavigate();
    const token = getUrlParam("token");
    const { setReport } = useContext(AppContext);

    useEffect(() => {
        const createWebSocket = async () => {
            const jwt = await getJwt();
            if (jwt) {
                ws.current = new WebSocket(`${WS_BASE_URL}?token=${jwt}`);

                ws.current.onmessage = ({ data }) => {
                    try {
                        setReport(JSON.parse(data) as Partial<Report>);
                        navigate(`${SUMMARY}?token=${token}`);
                    } catch (error) {
                        console.warn(error);
                        navigate(`${ERROR}?token=${token}`);
                    }
                };


                return () => {
                    ws.current?.close();
                };
            }
        }
        createWebSocket();
    }, [navigate, setReport, token, getJwt]);
}
export default useWebSocket;
