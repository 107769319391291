import React, { useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";

import Layout from "../layout";
import getLogo from "../../utils/brand";
import AppContext from "../../app-context";
import getUrlParam from "../../utils/url";
import { HOME } from "../../constants/routes";
import { useNavigate } from "react-router-dom";

const ErrorNotFound = () => {
  const navigate = useNavigate();
  const jwt = localStorage.getItem("jwt");
  const token = getUrlParam("token");
  const { report } = useContext(AppContext);
  const logo = getLogo(report);

  useEffect(() => {
    if (jwt) {
      const timeoutId = setTimeout(() => {
        navigate(`/${HOME}?token=${token}`);
      }, 60000); // Time will increase, 1 minute for debug
      return () => clearTimeout(timeoutId);
    }
  }, [navigate, token, jwt]);

  return (
    <Layout className="justify-center">
      <div className="flex flex-col text-center text-1.5vw gap-12">
        <img src={logo} alt="Logo" />
        <FormattedMessage id="error" defaultMessage="Error" tagName="p" />
        <FormattedMessage
          id="error404.notFound"
          defaultMessage="Page not found"
          tagName="p"
        />
      </div>
    </Layout>
  );
};
export default ErrorNotFound;
