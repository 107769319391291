import fetchContext from "../services/context";
import createMfaCode from "../services/mfa-create";
import fetchTranslations from "../services/translations";

async function initApp() {
    try {
        await createMfaCode();
        const context = await fetchContext();
        const language = context?.dealer.config.general.customerDefaultLanguage;
        const messages = await fetchTranslations(language)
        return { context, messages };
    } catch (error) {
        throw new Response(error as BodyInit);
    }
}

export default initApp;
