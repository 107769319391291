import jwt_decode, {JwtPayload} from "jwt-decode";
import { isBefore } from 'date-fns'

import login from "../services/login";

const useJwt = () => {
    return {
        getJwt: async () => {
            let jwt = localStorage.getItem("jwt");
            if (jwt !== null) {
                const decoded = jwt_decode<JwtPayload>(jwt);
                if (decoded.exp !== undefined) {
                    const expiration = new Date(decoded.exp * 1000);

                    if (isBefore(expiration, new Date())) {
                        try {
                            const { token } = await login();
                            jwt = token;
                            if (jwt) {
                                localStorage.setItem("jwt", jwt);
                            }
                        } catch (error) {
                            console.warn(error);
                        }
                    }
                }
            }
            return jwt;
        }
    }
}

export default useJwt;