import React, {InputHTMLAttributes, useCallback, useContext, useState} from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import OTPInput from "react-otp-input";
import classNames from "classnames";

import Label from "./label";
import Layout from "./layout";
import login from "../services/login";
import AppContext from "../app-context";
import getUrlParam from "../utils/url";
import { CODE_LENGTH } from "../constants";
import { HOME } from "../constants/routes";
import verifyMfaCode from "../services/mfa-verify";
import CurrentTimeDisplay from "./current-time-display";

const Login = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState<string>();
  const shortToken = getUrlParam("token");
  const [isError, setIsError] = useState(false);
  const { context } = useContext(AppContext);

  const isDisabled = !code;

  const handleSubmit = async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (code?.length === CODE_LENGTH) {
      try {
        await verifyMfaCode(context.id, code);
        const { token } = await login();
        token && localStorage.setItem("jwt", token);
        navigate(`/${HOME}?token=${shortToken}`);
      } catch (error) {
        setIsError(true);
      }
    }
  };

  const renderInput = useCallback((props: InputHTMLAttributes<HTMLInputElement>) => {
    return <input {...props} />;
  }, []);

  return (
    <Layout className="flex-col px-32 py-14">
      <div className="flex justify-end w-full">
        <div className="flex px-9 py-6 gap-3 border-white border rounded-large text-1.5vw">
          <CurrentTimeDisplay
            year="numeric"
            month="long"
            weekday="long"
            day="numeric"
            hour="2-digit"
            minute="2-digit"
          />
        </div>
      </div>
      <div className="flex items-center justify-center h-full">
        <div className="flex flex-col rounded-2xl bg-white p-10 gap-10">
          <Label
            reverse
            content={
              <FormattedMessage
                id="verification.code"
                defaultMessage="Verification code"
              />
            }
            title={
              <FormattedMessage
                id="verification.mail"
                defaultMessage="We have sent the verification code to your mailbox."
              />
            }
          />
          <form onSubmit={handleSubmit} className="flex flex-col gap-6">
            <OTPInput
              value={code}
              numInputs={CODE_LENGTH}
              inputType="number"
              onChange={setCode}
              shouldAutoFocus={true}
              containerStyle="gap-2.5 w-full justify-center"
              renderInput={renderInput}
              inputStyle={classNames(
                "grow outline-none h-9vh text-2vw text-blue bg-input-bg rounded-lg border border-input-bg active:border-input-bg focus:border-blue",
                {
                  "border-red text-red active:border-input-error focus:border-red":
                    isError,
                }
              )}
            />
            {isError && (
              <div className="text-center text-red text-1.5vw">
                <FormattedMessage
                  id="verification.error"
                  defaultMessage="Invalid verification code"
                />
              </div>
            )}
            <button
              type="submit"
              className="flex items-center justify-center bg-blue rounded-3xl gap-2.5 py-6 px-9 text-1.5vw w-full"
              disabled={isDisabled}
            >
              <FormattedMessage
                id="verification.login"
                defaultMessage="Login"
              />
            </button>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
