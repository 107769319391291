import React, { useContext, useEffect } from "react";
import { Outlet, useLoaderData } from "react-router-dom";

import Loader from "./loader";
import AppContext from "../app-context";
import {LoaderData} from "../models/loaderData";

const Root = () => {
  const { context: contextPayload, messages: messagesPayload } = useLoaderData() as LoaderData;
  const { context, setContext, messages, setMessages, setLocale } = useContext(AppContext);

  useEffect(() => {
    if (contextPayload?.dealer && contextPayload.id) {
      setContext(contextPayload);
      setMessages(messagesPayload);
      setLocale(contextPayload?.dealer.config.general.customerDefaultLanguage);
    }
  }, [contextPayload, messagesPayload, setContext, setLocale, setMessages]);

  const isLoading = Object.keys(context).length === 0 || Object.keys(messages).length === 0;

  return isLoading ? <Loader /> : <Outlet />;
};

export default Root;
