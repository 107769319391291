import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";

import AppContext from "../app-context";
import getUrlParam from "../utils/url";
import useWebSocket from "../hooks/use-websocket";

const ProtectedRoute = () => {
    useWebSocket();
    const token = getUrlParam('token')
    const { context } = useContext(AppContext);
    // Check if context state is empty on refresh
    return Object.keys(context).length !== 0 ? <Outlet /> : <Navigate to={`/?token=${token}`} />;
};

export default ProtectedRoute;
