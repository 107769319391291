import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

import Car from "./car";
import Label from "./label";
import Loader from "./loader";
import Navbar from "./navbar";
import AppContext from "../app-context";
import getUrlParam from "../utils/url";
import { HOME } from "../constants/routes";
import useTranslations from "../hooks/use-translations";
import {
  Civility,
  HIGH_DMG_LIST,
  LOW_DMG_LIST,
  NO_DMG_LIST,
} from "../constants";

const Summary = () => {
  const navigate = useNavigate();
  const token = getUrlParam("token");
  const { isTranslationsLoading, report } = useContext(AppContext);
  useTranslations(report?.serviceHeader?.customerInfo.language);

  const {
    noDmg = 0,
    smallDmg = 0,
    bigDmg = 0,
  } = report?.tchek?.damages?.reduce(
    (acc, { severity }) => {
      if (NO_DMG_LIST.includes(severity)) {
        ++acc.noDmg;
      }
      if (LOW_DMG_LIST.includes(severity)) {
        ++acc.smallDmg;
      }
      if (HIGH_DMG_LIST.includes(severity)) {
        ++acc.bigDmg;
      }
      return acc;
    },
    { noDmg: 0, smallDmg: 0, bigDmg: 0 }
  ) ?? {};

  const { companyName, civility, firstName, lastName } = report.serviceHeader?.customerInfo ?? {};
  let displayName = companyName;

  if (civility === Civility.MR || civility === Civility.MS) {
    if (firstName && firstName?.length > 0 && lastName && lastName?.length > 0) {
      displayName = `${firstName} ${lastName}`;
    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(`/${HOME}?token=${token}`);
    }, 60000); // Time will increase, 1 minute for debug

    return () => clearTimeout(timeoutId);
  }, [navigate, token, report]);

  return isTranslationsLoading ? (
    <Loader />
  ) : (
    <div className="flex flex-col h-screen overflow-hidden bg-defaultGrey">
      <Navbar />
      <div className="flex h-screen gap-8 p-8">
        <div
          className={classNames(
            "flex flex-col justify-around items-center rounded-small bg-white w-1/3 p-8 gap-8",
            { "w-full": !report?.tireScan }
          )}
        >
          <div className="flex flex-col gap-4">
            {Boolean(displayName) && (
              <Label
                title={
                  <FormattedMessage id="customer" defaultMessage="Customer" />
                }
                content={displayName}
              />
            )}
            <Label
              title={
                <FormattedMessage
                  id="vehicleRegistration"
                  defaultMessage="Vehicle Registration"
                />
              }
              content={report?.camera?.cameraRegistration.toUpperCase()}
            />
          </div>
          {!!report?.tchek?.damages?.length && (
            <>
              <div className="w-full h-0.5 tv:h-1 bg-lightGrey" />
              <div className="flex flex-col gap-4">
                <Label
                  title={
                    <FormattedMessage
                      id="bodyState.low"
                      defaultMessage="Low severity damages"
                    />
                  }
                  content={noDmg}
                />
                <Label
                  title={
                    <FormattedMessage
                      id="bodyState.medium"
                      defaultMessage="Medium severity damages"
                    />
                  }
                  content={smallDmg}
                />
                <Label
                  title={
                    <FormattedMessage
                      id="bodyState.high"
                      defaultMessage="High severity damages"
                    />
                  }
                  content={bigDmg}
                />
              </div>
            </>
          )}
        </div>
        {report?.tireScan && (
          <div className="flex w-2/3 p-8 rounded-small bg-white">
            <Car tires={report.tireScan.tires} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Summary;
