import React from "react";
import classNames from "classnames";

import svg from "../assets/Icons/mask-home.svg";

type LayoutProps = {
    children: React.ReactNode;
    className?: string;
}

const Layout = ({ children, className }: LayoutProps) => {
  return (
    <div
      className={classNames(
        "flex items-center bg-blue text-white font-bold h-screen",
        className
      )}
    >
      {children}
      <img src={svg} alt="icon" className="absolute w-1/4 bottom-0 right-0" />
    </div>
  );
};

export default Layout;
