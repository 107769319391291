import {Report} from "../models/report";
import {LanguageEnum} from "../models/enums/language.enum";

export const DEFAULT_CONTEXT = {
    context: {},
    locale: LanguageEnum.EN,
    report: {},
    messages: {},
    isTranslationsLoading: false,
    setContext: (context: any) => { },
    setLocale: (locale: string) => { },
    setReport: (report: Partial<Report>) => { },
    setMessages: (messages: any) => { },
    setIsTranslationsLoading: (isTranslationsLoading: boolean) => { }
};