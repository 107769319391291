import restClient from ".";
import { LOGIN } from "../constants/api-url";

async function login() {
    try {
        const { data } = await restClient.post(LOGIN, { secret: 'abc' });
        return data;
    } catch (error) {
        throw new Response(error as BodyInit);
    }
}

export default login;