import restClient from ".";
import { MFA_CREATE } from "../constants/api-url";

async function createMfaCode() {
    try {
        await restClient.post(MFA_CREATE);
    } catch (error) {
        throw new Response(error as BodyInit);
    }
}

export default createMfaCode;