import React, { useState } from "react";
import { RouterProvider } from "react-router-dom";

import router from "./routes/router";
import AppContext from "./app-context";
import IntlWrapper from "./components/translations";
import {DEFAULT_CONTEXT} from "./constants/context";
import {LanguageEnum} from "./models/enums/language.enum";

function App() {
  const [context, setContext] = useState(DEFAULT_CONTEXT.context);
  const [locale, setLocale] = useState<LanguageEnum>(DEFAULT_CONTEXT.locale);
  const [report, setReport] = useState(DEFAULT_CONTEXT.report);
  const [messages, setMessages] = useState(DEFAULT_CONTEXT.messages);
  const [isTranslationsLoading, setIsTranslationsLoading] = useState(DEFAULT_CONTEXT.isTranslationsLoading);

  return (
    <AppContext.Provider value={{ context, isTranslationsLoading, locale, messages, report, setContext, setIsTranslationsLoading, setLocale, setMessages, setReport }}>
      <IntlWrapper>
        <RouterProvider router={router} />
      </IntlWrapper>
    </AppContext.Provider>
  );
}

export default App;
